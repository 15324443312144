@if (!embedded) {
  <schaeffler-app-shell
    [appTitle]="title"
    [appTitleLink]="'/'"
    [hasSidebarLeft]="true"
    [footerLinks]="footerLinks$ | ngrxPush"
    [appVersion]="appVersion"
  >
    <ng-container sidenavBody>
      <mm-settings></mm-settings>
    </ng-container>
    <ng-container mainContent>
      <div class="h-full w-full">
        <schaeffler-banner></schaeffler-banner>
        <div class="border-t border-border"></div>
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </ng-container>
  </schaeffler-app-shell>
}
@if (embedded) {
  <div class="fixed h-full w-full">
    <div class="fixed z-10 bg-surface">
      <button (click)="drawer.toggle()" mat-icon-button aria-label="settings">
        <mat-icon class="text-icon">menu</mat-icon>
      </button>
    </div>
    <mat-drawer-container class="h-full">
      <mat-drawer #drawer class="example-sidenav" mode="over">
        <mm-settings #sidebar [embedded]="embedded"></mm-settings>
      </mat-drawer>
      <schaeffler-banner></schaeffler-banner>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mat-drawer-container>
  </div>
}

<ng-template #content>
  <div
    [ngClass]="embedded ? 'bg-surface' : 'bg-background-dark'"
    class="flex min-h-full flex-col"
  >
    <div
      [ngClass]="embedded ? 'm-0 mt-5 p-0 pt-5' : 'px-4 pb-4 md:m-4'"
      class="flex flex-auto flex-col bg-surface"
    >
      <div class="max-w-full flex-1">
        <router-outlet></router-outlet>
      </div>
      <div
        class="mx-auto w-full max-w-screen-md"
        [ngClass]="{ hidden: !isCookiePage }"
      >
        <div class="py-3 px-4">
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">
            {{ cookieSettings }}
          </button>
        </div>
        <div class="py-3 md:px-4">
          <div id="ot-sdk-cookie-policy"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
