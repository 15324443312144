<div *transloco="let t; read: 'sidebar'">
  <div class="w-full px-4" [ngClass]="embedded ? 'pt-12' : 'pt-4'">
    <div class="mb-1 text-subtitle-1 text-medium-emphasis">
      {{ t('language.title.main') }}
    </div>
    <div class="mb-4 text-caption text-medium-emphasis">
      {{ t('language.title.sub') }}
    </div>
    <schaeffler-language-select
      [reloadOnLanguageChange]="true"
      class="mb-3 block"
    ></schaeffler-language-select>
    <mat-form-field appearance="outline" class="w-full">
      <mat-label class="!min-w-fit !w-fit pr-1 !block"
        ><span class="!min-w-fit !w-fit pr-1 !block">{{
          t('decimalSeparatorSelectLabel')
        }}</span></mat-label
      >
      <mat-select
        class="hover:outline-green focus:outline-green"
        [formControl]="separatorSelectControl"
        (selectionChange)="setSeparator($event.value)"
      >
        @for (seperator of availableSeparators; track $index) {
          <mat-option [value]="seperator.id">{{
            t(seperator.label)
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
</div>
